<template>
  <div class="experiences" data-view>
    <hero
      title="neera news"
      description="stay updated with the latest news and stories from <span class='saoltext'>‘neera’</span>"
      class="my-18 lg:mb-20 lg:mt-8"
    />
    <tab-menu
      :items="news_types.map(t => {
        return {
          ...t,
          id: t.attributes.slug
        }
      })"
      all-item-label="all articles"
      :default-tab="0"
      path-name="whats-new"
      path-id="type"
      :has-all="true"
    />
    <div
      id="activities"
      class="px-4 lg:px-8 max-w-screen-lg mx-auto mb-18 lg:mb-20"
    >
      <TransitionGroup
        name="fade"
        tag="discover-item"
        class="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-x-8 lg:gap-y-10"
      >
        <discover-item
          v-for="(item, index) in news_items"
          :key="index"
          :item-id="item.id"
          :image-url="
            getImageByFormat(item.attributes.cardImage || item.attributes.image)
          "
          :item-title="item.attributes.title"
          :badge="item.attributes.type.data.attributes.name"
          :item-description="item.attributes.description"
          :item-detail-path="`/whats-new/${item.attributes.slug}`"
          :isPin="item.attributes.pin"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import { getNewsType, getNewsList } from "@/api/endpoints/news";
import to from "await-to-js";
import DiscoverItem from "@/components/DiscoverItem.vue";
import Hero from "../../components/Hero.vue";
import TabMenu from "@/components/TabMenu.vue";
import { getImageUrl } from "@/utils/imageUtils.js";

export default {
  name: "NewsList",
  components: {
    DiscoverItem,
    Hero,
    TabMenu,
  },
  created() {
    const title = "neera news";
    const description =
      "stay updated with the latest news and stories from ‘neera’";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  data() {
    return {
      lang: "en",
      news_type_id: 0,
      defaultTabId: 0,
      news_types: [],
      news_items: [],
      all_news_items: [],
    };
  },
  watch: {
    "$route.query.type": async function (news_type) {
      const news_type_id = this.news_types.find(t => t.attributes.slug === news_type)?.id

      if (news_type_id) {
        this.news_items = this.all_news_items.filter(
          (i) => i.attributes.type.data.id == news_type_id
        );
      } else {
        this.news_items = this.all_news_items;
      }
    },
  },
  mounted() {
    this.news_type = this.$route.query.type
      ? this.$route.query.type
      : 0;
    this.setNewsType(this.lang);
    this.setNewsList(this.news_type_id, this.lang);
  },
  methods: {
    getImageByFormat(image) {
      if (image) return getImageUrl(image.data);
      return "";
    },
    async setNewsType(lang) {
      const [err, response] = await to(getNewsType(lang));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      this.news_types = response.data.data;
      if (this.defaultTabId == 0 && this.news_types.length > 0)
        this.defaultTabId = this.news_types[0].id;
    },
    async setNewsList(typeId, lang) {
      const [err, response] = await to(getNewsList(0, lang));

      console.log(response.data.data.map(d => (d.id)))

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      this.all_news_items = response.data.data;

      const news_type_id = this.news_types.find(t => t.attributes.slug === this.news_type)?.id

      if (news_type_id) {
        this.news_items = this.all_news_items.filter(
          (i) => i.attributes.type.data.id == news_type_id
        );
      } else {
        this.news_items = this.all_news_items;
      }
    },
  },
};
</script>

<style scoped>
.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.menu {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5c623f;
}

#news-content {
  border-top: 1px solid #cecdc8;
}

.discover-more {
  background: #5c623f;
  color: white;
}
</style>
